jQuery(document).ready(function ($) {

    /*$('.xoo-wsc-basket').hide();*/
    $('.js-header-cart-icon').on('click', function (e) {
        /*$('.xoo-wsc-basket').trigger('click');*/
//TODO: Create mini cart
        location.href = '/cart';
    });


});
