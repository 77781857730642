jQuery(document).ready(function ($) {

    var slider = $('.js-products-slider');

    if (slider.length > 0) {

        slider.slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            dots: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2, // Match slidesToScroll with slidesToShow
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
        });

        // Handle Click on Custom Dots
        $('.js-product-slider-pager').on('click', function () {
            var index = parseInt($(this).attr('data-index'));
            var slidesToScroll = slider.slick('slickGetOption', 'slidesToScroll');
            slider.slick('slickGoTo', index);

            // Update Active Dot
            $('.js-product-slider-pager').removeClass('active');
            $(this).addClass('active');
        });

        // Update Active Dot on Slide Change
        slider.on('afterChange', function (event, slick, currentSlide) {
            var currentSlide = slick.currentSlide;
            var slidesToScroll = getCurrentSlidesToScroll(slick); // Get current slidesToScroll
            var activeDot = Math.floor(currentSlide / slidesToScroll);

            getSlideWidth(slick);
            getSlideIndex(slick);
            removeExtraPagers();

            var totalDots = $('.js-product-slider-pager').length;
            if (activeDot >= totalDots) {
                activeDot = totalDots - 1;
            }

            var activeDot = Math.floor(currentSlide / slidesToScroll);
            $('.js-product-slider-pager').removeClass('active');
            $('.js-product-slider-pager').eq(activeDot).addClass('active');
        });


        // Optional: Handle window resize to update active dot if necessary
        $(window).on('resize', function () {
            var slickInstance = slider.slick('getSlick');

            getSlideWidth(slickInstance);
        });


        // Function to generate slide width based on window size
        function getSlideWidth(slickInstance) {
            var sliderWidth = slider.outerWidth();
            var slidesToShow = slickInstance.options.slidesToShow;

            var finalWidth = Math.floor((sliderWidth - 80) / slidesToShow);

            $('.js-products-slider').find('.slick-slide').css('width', finalWidth.toString() + 'px');
        }

        getSlideWidth(slider.slick('getSlick'));

        // Function to match pagination indices to actual slider
        function getSlideIndex(slickInstance) {
            var dots = $('.js-product-slider-pager');
            var slidesToShow = slickInstance.options.slidesToShow;

            var i = 0;
            while (dots.get(i)) {
                var currentDot = dots.get(i);
                var currentSlideIndex = slidesToShow * i;

                $(currentDot).attr('data-index', currentSlideIndex.toString());

                i++;
            }
        }

        getSlideIndex(slider.slick('getSlick'));

        // Function to remove extraneous pagers
        function removeExtraPagers(slickInstance) {
            var numSlides = $('.js-products-slider').find('.slick-slide:not(.slick-cloned)').length;
            var dots = $('.js-product-slider-pager');

            var currentDot;
            var currentDotIndex;

            var i = 0;
            while (dots.get(i)) {
                currentDot = dots.get(i);
                currentDotIndex = parseInt($(currentDot).attr('data-index'));

                if (currentDotIndex >= numSlides) {
                    $(dots).slice(i).remove();
                    break;
                }
                i++;
            }

        }

        removeExtraPagers()

        // Function to get current slidesToScroll based on viewport
        function getCurrentSlidesToScroll(slickInstance) {

            var slidesToScroll = slickInstance.options.slidesToScroll;
            var windowWidth = $(window).width();
            var responsiveSettings = slickInstance.options.responsive;

            if (responsiveSettings) {
                for (var i = 0; i < responsiveSettings.length; i++) {
                    if (windowWidth <= responsiveSettings[i].breakpoint) {
                        slidesToScroll = responsiveSettings[i].settings.slidesToScroll;
                    }
                }
            }

            return slidesToScroll;
        }
    }
    

});