jQuery(document).ready(function ($) {
    $('.js-announcements-slider').slick({
        infinite: true,
        speed: 300,
        arrows: true,
        dots: false,
        slidesToShow: 1,
        prevArrow: '<div class="pre-header__arrow pre-header__arrow--prev"><img src="/wp-content/themes/ellenvale/static/images/arrow-left.svg" alt="Previous" /></div>',
        nextArrow: '<div class="pre-header__arrow pre-header__arrow--next"><img src="/wp-content/themes/ellenvale/static/images/arrow-right.svg" alt="Next"></div>',
        autoplay: true,           // Enables autoplay
        autoplaySpeed: 6000,       // 6000 milliseconds = 6 seconds
        adaptiveHeight: true
    });
});
