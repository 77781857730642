jQuery(document).ready(function ($) {
    // Get the checkbox and the shipping address container.
    var shipToDifferentAddressCheckbox = $('.js-shipping-checkbox');
    var shippingAddressContainer = $('.js-shipping-details');

    // Function to toggle the shipping address visibility.
    function toggleShippingAddress() {
        console.log('toggleShippingAddress()');
        if (shipToDifferentAddressCheckbox.is(':checked')) {
            shippingAddressContainer.slideDown();
        } else {
            shippingAddressContainer.slideUp();
        }
    }

    // Add event listener to the checkbox.
    if (shipToDifferentAddressCheckbox.length) {
        shipToDifferentAddressCheckbox.on('change', toggleShippingAddress);

        // Initial toggle on page load.
        toggleShippingAddress();
    }
});


$(document).on('updated_checkout', function() {
    // Re-check if wc_stripe_form is available
    if (typeof wc_stripe_form !== 'undefined' && wc_stripe_form.hasOwnProperty('upe_form')) {
        console.log('Reinitialising Stripe payment options after fragment refresh.');
        wc_stripe_form.upe_form.initialize();
    } else {
        console.log('Stripe form is not available after fragment refresh.');
    }
});

$(document).on('updated_checkout', function() {
    console.log('Checkout fragment updated.');
});

$(document).on('updated_checkout', function() {
    // Trigger payment method update to refresh Stripe fields
    $('form.checkout').trigger('update_checkout');
});

$(document).on('updated_checkout', function() {
    if (typeof wc_stripe_form !== 'undefined' && wc_stripe_form.hasOwnProperty('upe_form')) {
        // Reinitialise Stripe fields
        console.log('Manually reinitialising Stripe after fragment refresh.');
        wc_stripe_form.upe_form.initialize();
    } else {
        console.log('Stripe form not available after fragment refresh.');
    }
});

