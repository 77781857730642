jQuery(document).ready(function ($) {
    $('.js-featured-in-slider').slick({
        slidesToShow: 10,
        arrows: false,
        centerMode: true,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Set autoplay speed in milliseconds
        infinite: true, // Enable infinite looping
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 8
                }
            },
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    centerMode: false,
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 700,
                settings: {
                    centerMode: false,
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 550,
                settings: {
                    centerMode: false,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 360,
                settings: {
                    centerMode: false,
                    slidesToShow: 2
                }
            }
        ]
    });
});
