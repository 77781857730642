jQuery(document).ready(function ($) {
    $('.js-apply-gift-card').click(function () {
        var giftCardCode = $('#gift_card_code').val();

        $.ajax({
            url: '/wp-admin/admin-ajax.php',  // WordPress AJAX URL
            type: 'POST',
            data: {
                action: 'apply_gift_card',
                gift_card_code: giftCardCode
            },
            success: function (response) {
                if (response.success) {
                    dialogify('Gift card applied: ' + response.data.message);
                    location.reload(); // Reload cart to apply discount
                } else {
                    dialogify('Error: ' + response.data.message);
                }
            }
        });
    });
});
