jQuery(document).ready(async function($) {
    let products = $('.blog-product:not(.blog-product--empty)');


    if ($('body').hasClass('single-post')) {
        let i = 0;
        while (i < $(products).length) {
            let id = $($(products).get(i)).attr('id');


            if (id !== '') {
                // Send AJAX request
                await $.ajax({
                    url: blog_product.ajax_url,
                    type: 'POST',
                    async: true,
                    data: {
                        action: 'blog_product',
                        page: 1,
                        id: id,
                        nonce: blog_product.nonce
                    },
                    beforeSend: function() {

                    },
                    success: function(response) {
                        if(response) {
                            $($($(products).get(i)).children().get(0)).html($(response).children());
                        }
                    },
                    error: function() {
                        //console.log('failed' + i);
                        $($($(products).get(i)).children().get(0)).html('<span class="blog-product__loading">This product does not exist.</span>');
                    },
                    complete: function () {
                    }
                });
            } else {
                //console.log('missing' + i);
                $($($(products).get(i)).children().get(0)).html('<span class="blog-product__loading">No product ID was provided.</span>');
            }

            i++;
        }
    }
}); 