jQuery(document).ready(function ($) {

    $('body').on('click', '.js-add-to-bag', function (e) {
        e.preventDefault();

        $(this).addClass('working');

        // Find the closest product card
        var $productCard = $(this).closest('.js-product-card');
        var productId = $productCard.data('id'); // Get the main product ID
        // Check if the product card contains variations
        if ($productCard.find('.js-select-product-variation').length) {
            // Get the active variation's data-id
            var $activeVariation = $productCard.find('.js-select-product-variation.active');

            if ($activeVariation.length) {
                var variationId = $activeVariation.data('id'); // Get the variation ID
                // Add variation to basket
                addToBasket(variationId);
            } else {
                // No active variation, show dialog
                var message = 'Please select a variation before adding to the bag.';
                dialogify(message);
                $('.js-add-to-bag').removeClass('working');
            }
        } else {
            // No variations, add the main product
            addToBasket(productId);
        }
    });

    function addToBasket(productId) {

        $.ajax({
            url: ajax_object.ajax_url,
            method: 'POST',
            data: {
                action: 'add_to_basket',
                product_id: productId
            },
            success: function (response) {
                $('.js-add-to-bag').removeClass('working');

                showMessage(response.data.message);

                // Check if the response is successful
                if (response.success) {
                    // Access response.data.cart_count directly
                    var cartCount = response.data.cart_count;
                    $('.js-header-cart-quantity').text(cartCount); // Update cart quantity display

                } else {
                    console.error('Error adding product to basket:', response.data); // Access error message here
                }
            },
            error: function (xhr, status, error) {
                console.error('Error adding product to basket:', error);
                console.error('Response Text:', xhr.responseText);
                $('.js-add-to-bag').removeClass('working');
                showMessage(xhr.responseText);
            }
        });
    }


    $('body').on('click', '.js-select-product-variation', function () {
        // Get the parent product card
        var productCard = $(this).closest('.js-product-card');

        // Remove active class from all visible variations within the same product card
        productCard.find('.js-select-product-variation').removeClass('active');

        // Add active class to the clicked variation
        $(this).addClass('active');
    });


    $('body').on('click mouseover', '.js-quick-buy', function () {
        var quick_buy_button = $(this);
        var product_card = quick_buy_button.closest('.js-product-card');
        var add_button = product_card.find('.js-add-to-bag');
        var options = product_card.find('.js-product-card-options');

        quick_buy_button.removeClass('active');
        add_button.addClass('active');
        options.addClass('active');
    });

    $('body').on('click', '.js-close-quick-buy', function () {

        var close_button = $(this);
        var product_card = close_button.closest('.js-product-card');
        var quick_buy_button = product_card.find('.js-quick-buy');
        var add_button = product_card.find('.js-add-to-bag');
        var options = product_card.find('.js-product-card-options');

        quick_buy_button.addClass('active');
        add_button.removeClass('active');
        options.removeClass('active');
    });

});

function showMessage(message) {
    // Remove any previous message
    jQuery('.js-message').remove();

    // Append the new structured message element
    jQuery('body').append(
        '<div class="js-message message">' +
        '<div class="message__inner">' + message + '</div>' +
        '</div>'
    );

    // Add the visible class after a short delay
    setTimeout(function() {
        jQuery('.js-message').addClass('visible');
    }, 100); // delay in milliseconds to add the visible class

    // Remove the visible class after a few seconds
    setTimeout(function() {
        jQuery('.js-message').removeClass('visible');
    }, 3000); // adjust as needed for how long the message should stay visible
}
