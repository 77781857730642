jQuery(document).ready(function($) {
    $('.dashboard__wishlist--module-top button').on('click', function() {
        $('.dashboard__wishlist--module-rename').toggleClass('open');
    });

    $('#rename_button').on('click', function() {
        let name = $('#rename_input').val();

        $('.dashboard__wishlist--module-top h3').text(name);
    });

    const numberInput = $('.dashboard__wishlist .input-text');

    function stepUp() {
        numberInput[0].stepUp();
        numberInput[1].stepUp();
        numberInput[0].dispatchEvent(new Event('change'));
    }

    function stepDown() {
        numberInput[0].stepDown();
        numberInput[1].stepDown();
        numberInput[0].dispatchEvent(new Event('change'));
    }

    $('.dashboard__wishlist .js-more-product-quantity').on('click', function(e) {
        stepUp();
        e.stopImmediatePropagation();
    });

    $('.dashboard__wishlist .js-less-product-quantity').on('click', function(e) {
        stepDown();
        e.stopImmediatePropagation();
    });
});