jQuery(document).ready(function ($) {
    function initCountdown() {
        console.log('initCountdown()');

        if ($('.js-countdown-timer').length > 0) {
            var time = $('.js-countdown-timer').attr('data-time'); // Date in format MM/DD/YYYY hh:mm:ss

            var countdownTime = new Date(time);
            var now = new Date();

            // If the countdown time has already passed today, set it for the same time tomorrow
            if (now > countdownTime) {
                countdownTime.setDate(countdownTime.getDate() + 1);
            }

            $('.js-countdown-timer').countdown(countdownTime)
                .on('update.countdown', function (event) {
                    var totalHours = event.offset.totalHours;
                    var totalMinutes = event.offset.minutes;
                    var totalSeconds = event.offset.seconds;
                    var message;

                    if (totalHours > 0) {
                        message = 'Get it tomorrow. Order in the next ' + totalHours + ' hour' + (totalHours > 1 ? 's' : '') + ', ' + totalMinutes + ' minute' + (totalMinutes > 1 ? 's' : '') + ' and ' + totalSeconds + ' second' + (totalSeconds > 1 ? 's' : '') + ' for next day delivery';
                    } else if (totalMinutes > 0) {
                        message = 'Get it tomorrow. Order in the next ' + totalMinutes + ' minute' + (totalMinutes > 1 ? 's' : '') + ' and ' + totalSeconds + ' second' + (totalSeconds > 1 ? 's' : '') + ' for next day delivery';
                    } else {
                        message = 'Get it tomorrow. Order in the next ' + totalSeconds + ' second' + (totalSeconds > 1 ? 's' : '') + ' for next day delivery';
                    }

                    $(this).html(message);
                })
                .on('finish.countdown', function (event) {
                    // Set countdown for the same time tomorrow
                    var newTime = new Date();
                    newTime.setHours(countdownTime.getHours());
                    newTime.setMinutes(countdownTime.getMinutes());
                    newTime.setSeconds(countdownTime.getSeconds());
                    newTime.setDate(newTime.getDate() + 1);

                    $(this).countdown(newTime).html('Order in the next 24 hours for next day delivery');
                });
        }
    }

    initCountdown();
});