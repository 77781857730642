function fireWhenFragmentReady() {
    jQuery(document.body).trigger('wc_fragment_refresh');
}

jQuery(document).ready(function($) {
    let mobile = false;

    fireWhenFragmentReady();

    function moveQuantity(refresh) {
        let quantities = $('.product-quantity');
        let cartItems = $('.cart_item');
        let remove = $(cartItems).find('.remove');

        let n = $(quantities).length;
        let x = 0;

        if ($(window).width() <= 768) {
            if (!mobile || refresh) {
                while (x < n) {
                    $($(quantities).get(x + 1)).detach().appendTo($(cartItems.get(x)).find('.product__title'));
                    $($(remove).get(x)).detach().appendTo($(cartItems.get(x)));
                    mobile = true;
                    x++;
                }
            }
        } else {
            if (mobile || refresh) {
                while (x < n) {
                    $($(quantities).get(x + 1)).detach().insertAfter($(cartItems.get(x)).find('.price'));
                    $($(remove).get(x)).detach().appendTo($(cartItems.get(x)).find('.product'));
                    mobile = false;
                    x++;
                }
            }
        }
    }

    moveQuantity();

    $(window).resize(function() {
        moveQuantity();
    });

    $(document.body).on('updated_wc_div', function() {
        moveQuantity(true);
    });
});