jQuery(document).ready(function($) {
    let $header = $('.js-header');
    let $mobileHeader = $('.js-mobile-header');

    function addScroll(window) {
        if (window.scrollTop() > 500) {
            $header.addClass('header__scroll');
            $mobileHeader.addClass('mobile-header__scroll');
        } else {
            $header.removeClass('header__scroll');
            $mobileHeader.removeClass('mobile-header__scroll');
        }
    }

    $(window).on('scroll', function() {
        addScroll($(this));
    });

    addScroll($(window));
});