jQuery(document).ready(function($) {
    $('.js-select-tab').on('click', function() {
        var ref = $(this).data('ref'); // Get the data-ref attribute of the clicked element

        // Add .active to the clicked element, remove from others
        $('.js-select-tab').removeClass('active');
        $(this).addClass('active');

        // Show the matching .js-tab by removing .hidden, hide others by adding .hidden
        $('.js-tab').each(function() {
            if ($(this).data('ref') === ref) {
                $(this).removeClass('hidden');
            } else {
                $(this).addClass('hidden');
            }
        });
    });
});