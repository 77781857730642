jQuery(document).ready(function ($) {

    var slider = $('.js-testimonials-slider');

    slider.slick({
        slidesToShow: 2,
        arrows: false,
        centerMode: true,
        centerPadding: '220px',
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
            {
                breakpoint: 1300, // Adjust this value as needed
                settings: {
                    slidesToShow: 1,
                    centerPadding: '40px',
                    centerMode: true,
                }
            }
            // You can add more breakpoints here if necessary
        ],
    });

});
