jQuery(document).ready(function ($) {

    // Set height on page load
    setMobileNavHeight();

    //setTimeout(setMobileNavHeight, 1000);

    // Run setMobileNavHeight() after Slick Slider has been initialized
    $('.js-announcements-slider').on('init', function (event, slick) {
        setMobileNavHeight();
    });

    // Recalculate height if the window is resized
    $(window).resize(function () {
        setMobileNavHeight();
    });

    /*Load top level menu into place if empty*/
    if ($('.js-mobile-nav-links').is(':hidden') || $('.js-mobile-nav-links').is(':empty')) {
        fillDefaultMobileNav();
    }

    $('body').on('click', '.js-mobile-parent-link', function (e) {
        if ($(e.target).is('.js-mobile-parent-link')) {
            var dataRef = $(this).data('ref');
            var subNavContents = $('.js-mobile-subnav[data-ref="' + dataRef + '"]').html();
            $('.js-mobile-nav-links').html(subNavContents);
        }
    });

    function updateSubNavPosition(element) {

        // Get the data-ref attribute of the clicked element
        var dataRef = element.attr('data-ref');

        // Find the .js-sub-nav with the same data-ref attribute and get its HTML content
        var subNav = $('.js-sub-nav[data-ref="' + dataRef + '"]');
        var subNavContent = subNav.html();

        // Show the dropdown container
        $('.js-nav-dropdown').show();

        $('.js-overlay').show();

        // Insert the sub-navigation content into the subnav links container
        $('.js-subnav-links').html(subNavContent);

        // Check if the sub-nav has data-position="center"
        var dataPosition = subNav.attr('data-position');

        if (dataPosition === 'center') {
            // Center the .js-subnav-links horizontally on the screen
            var windowWidth = $(window).width();
            var subNavWidth = $('.js-subnav-links').outerWidth();
            var leftPosition = (windowWidth / 2) - (subNavWidth / 2);

            // Apply the calculated left position to center the subnav on the screen
            $('.js-subnav-links').css({
                'left': leftPosition + 'px',
                'right': 'auto'
            });
        } else {
            // Default behavior: position relative to the clicked .js-parent-link element
            var parentPosition = element.offset();
            var parentWidth = element.outerWidth();
            var subNavWidth = $('.js-subnav-links').outerWidth();
            var leftPosition = parentPosition.left + (parentWidth / 2) - (subNavWidth / 2);

            // Apply the calculated left position relative to the parent element
            $('.js-subnav-links').css({
                'left': leftPosition + 'px',
                'right': 'auto'
            });
        }

        // Set the height of .js-nav-dropdown to match the .js-subnav-links element
        var subNavHeight = $('.js-subnav-links').outerHeight();
        $('.js-nav-dropdown').css('height', subNavHeight + 'px');

        //Focus on search input
        if (dataRef == 'search') {
            $('.js-search-input').focus();
        }
    }

    // Handle click event on .js-parent-link
    $('.js-parent-link').each(function () {
        var $link = $(this);

        // Check the data-on attribute
        if ($link.data('on') === 'hover') {
            // Use hover event
            $link.on('mouseover', function (e) {
                handleDropdown($link, e);
            });
        } else {
            // Use click event
            $link.on('click', function (e) {
                handleDropdown($link, e);
            });
        }
    });

    function handleDropdown(clickedElement, e) {
        setMobileNavHeight();
        e.stopPropagation(); // Prevents the document click event from firing

        // If the clicked element is already active, close the dropdown
        if (clickedElement.hasClass('active')) {
            hideDropdown();

            /*If was transparent, return to transparent*/
            if ($('body').hasClass('has-header--transparent')) {
                $('.js-header').addClass('header--transparent');
                $('.js-mobile-header').addClass('mobile-header--transparent');
            }

        } else {
            // Remove active class from all links before setting the clicked one
            $('.js-parent-link').removeClass('active');

            // Add active class to the clicked element and update position
            clickedElement.addClass('active');
            updateSubNavPosition(clickedElement);

            /*If transparent, temporarily make solid*/
            if ($('body').hasClass('has-header--transparent')) {
                $('.js-header').removeClass('header--transparent');
                $('.js-mobile-header').removeClass('mobile-header--transparent');
            }
        }
    }


    // Handle window resize to recalculate position
    $(window).resize(function () {
        var activeParentLink = $('.js-parent-link.active');
        if (activeParentLink.length) {
            updateSubNavPosition(activeParentLink);
        }
    });

    // Close dropdown when clicking outside of it
    $(document).click(function (e) {
        if (!$(e.target).closest('.js-nav-dropdown, .js-parent-link, .js-mobile-nav-links, .js-subnav-links').length && !$(e.target).is('.js-mobile-parent-link')) {
            hideDropdown();
        }
    });

// Function to hide the dropdown and remove active class
    function hideDropdown() {
        $('.js-nav-dropdown').hide(); // Hide the dropdown
        $('.js-parent-link.active').removeClass('active'); // Remove active class
        $('.js-subnav-links').html('');
        fillDefaultMobileNav();
        $('.js-overlay').hide();
    }

    function fillDefaultMobileNav() {
        var topNavContents = $('.js-mobile-subnav[data-ref="top"]').html();
        $('.js-mobile-nav-links').html(topNavContents);
    }

    function setMobileNavHeight() {
        var documentHeight = $('document').height();
        var preHeaderHeight = $('.js-pre-header').height();
        var mobileHeaderHeight = $('.js-mobile-header').height();
        var availableHeight = documentHeight - (preHeaderHeight + mobileHeaderHeight);
        $('.js-mobile-navigation').css('min-height', availableHeight + 'px');
    }
});
