jQuery(document).ready(function($) {
    var submitCategoryQuery = function(callback) {
        $.ajax({
            url: ajax_params.ajax_url,
            type: 'POST',
            data: {
                'action': 'sort_posts_by_category',
                'category': $('#post-archive__filter option:selected').attr('value')
            },
            beforeSend: function() {
                $('.post-archive__categories').html('Loading...');
            },
            success: function(response) {
                $('.post-archive__categories').html(response.data);

                if (callback !== undefined) {
                    callback(response);
                }
            },
            error: function() {
                $('.post-archive__categories').html('Error occurred');
            }
        });

    }

    $('#post-archive__filter').bind('change', submitCategoryQuery);
 });