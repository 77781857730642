jQuery(document).ready(function($) {
    var requestSent = false;
    var currentSort = 'most_recent';

    var productReviews = $('.product-reviews');

    var reviewList = $('.product-reviews__list');

    var loadButton = $('#load-more-comments');
    var id = parseInt(productReviews.attr('id'));
    var total = parseInt(productReviews.attr('total'));

    $('#product-reviews__filter option').on('click', function(e) {
        if (!requestSent) {
            var option = $(this);
            var newSort = option.attr('id');

            //console.log(newSort);

            $('#product-reviews__filter').prop('disabled', true);

            if (newSort !== currentSort) {
                sortComments(newSort, e);
            }

        }
    });

    loadButton.on('click', function(e) {
        if (!requestSent) {
            requestSent = true;
            //console.log(requestSent);

            loadMoreComments('load_more_comments', $(this), e);
        }
    });

    function sortComments(action) {
        // Send AJAX request
        $.ajax({
            url: comments_ajax.ajax_url,
            type: 'POST',
            async: true,
            data: {
                action: action,
                page: 1,
                id: id,
                sort: action,
                nonce: comments_ajax.nonce
            },
            beforeSend: function() {
                //console.log('fired');
                requestSent = true;
                loadButton.text('Loading...').prop('disabled', true);
                $(reviewList).addClass('loading');
            },
            success: function(response) {
                currentSort = action;

                if(response) {
                    $('.product-reviews__list').html($(response).children());

                    loadButton.attr('data-page', 1);
                    loadButton.text('Load More').prop('disabled', false);
                }

            },
            complete: function() {
                $(reviewList).removeClass('loading');
                $('#product-reviews__filter').prop('disabled', false);
                requestSent = false;
            }
        });
        return false;
    }

    function loadMoreComments(action, thisButton) {
        var page = parseInt(thisButton.attr('data-page'));
        var nextPage = page + 1;

        // Send AJAX request
        $.ajax({
            url: comments_ajax.ajax_url,
            type: 'POST',
            async: true,
            data: {
                action: action,
                page: nextPage,
                id: id,
                sort: currentSort,
                nonce: comments_ajax.nonce
            },
            beforeSend: function() {
                $(reviewList).addClass('loading');
                thisButton.text('Loading...').prop('disabled', true);
            },
            success: function(response) {
                //console.log([page * 2, total]);

                if(response) {
                    if (action === 'load_more_comments') {
                        $('.product-reviews__list').append($(response).children());
                    }

                    thisButton.attr('data-page', nextPage);
                    thisButton.text('Load More').prop('disabled', false);

                    if ((page * 2) + 2 >= total) {
                        thisButton.text('No more comments').prop('disabled', true).hide();
                        //console.log('hidden');
                    }
                } else {
                    thisButton.text('No more comments').prop('disabled', true);
                }

            },
            complete: function() {
                $(reviewList).removeClass('loading');
                requestSent = false;
            }
        });
        return false;
    }
});
