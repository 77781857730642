jQuery(document).ready(function ($) {
    // Main product gallery slider
    $('.js-product-gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,  // You can set this to false if you don’t want navigation arrows
        fade: true,    // If you want the main slider to fade between slides
        asNavFor: '.js-product-gallery-nav', // Connect with the thumbnail slider
    });

    // Thumbnail navigation slider
    $('.js-product-gallery-nav').slick({
        slidesToShow: 4,          // Number of thumbnails to show
        slidesToScroll: 1,        // Scroll one thumbnail at a time
        asNavFor: '.js-product-gallery-slider', // Connect with the main slider
        dots: false,              // No dots for thumbnails
        focusOnSelect: true,      // Enable clicking on thumbnails to navigate
        arrows: false,            // No arrows for thumbnails
        infinite: false,          // Disable infinite scrolling for smoother control
        variableWidth: true
    });

    // Handle the active class and slide the thumbnail into view
    $('.js-product-gallery-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var $thumbnailNav = $('.js-product-gallery-nav');

        // Remove active class from all thumbnails
        $thumbnailNav.find('.slick-slide').removeClass('slick-active-thumb');

        // Add active class to the next thumbnail
        var nextThumbnail = $thumbnailNav.find('.slick-slide').eq(nextSlide);
        nextThumbnail.addClass('slick-active-thumb');

        // Slide the next thumbnail to the left side of the container
        var slideIndex = nextSlide;
        $thumbnailNav.slick('slickGoTo', slideIndex);
    });

    /*

    function setSlideWidth() {
        let containerWidth = $('.js-product-gallery-slider').width();

        $('.product-gallery__slide').attr('style', 'width: ' + containerWidth + 'px !important');
    }

    setSlideWidth();
    $(window).resize(setSlideWidth);
     */

});