jQuery(document).ready(function ($) {
    var slider = $('.js-related-posts-slider');

    if (slider.length > 0) {
        slider.slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            centerMode: false,
            autoplay: false,
            infinite: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true
                    }
                }
            ]
        });
    
        $(window).on('resize', function () {
            var slickInstance = slider.slick('getSlick');
    
            getSlideWidth(slickInstance);
        });
    
        // Function for getting slide width
        function getSlideWidth(slickInstance) {
            var sliderWidth = slider.outerWidth();
            var slidesToShow = slickInstance.options.slidesToShow;
    
            var finalWidth = Math.floor(sliderWidth / slidesToShow);
    
            $('.slick-slide').attr('style', 'width: ' + finalWidth.toString() + 'px !important');
        }
    
        getSlideWidth(slider.slick('getSlick'));
    }
    
});