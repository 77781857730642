function addQuantityClickEvents() {
    let updateCart = jQuery('.cart__update button');

    jQuery('.js-less-product-quantity').on('click', function(e) {
        e.stopPropagation();
        var jQueryquantityInput = jQuery(this).next().next();
        var currentValue = parseInt(jQueryquantityInput.val());
        if (currentValue > 1) { // Ensuring the value doesn't go below 1
            jQueryquantityInput.val(currentValue - 1);
        }
        jQuery(updateCart).removeAttr('disabled');
    });

    jQuery('.js-more-product-quantity').on('click', function(e) {
        e.stopPropagation();
        console.log(jQuery(this).length);
        var jQueryquantityInput = jQuery(this).prev();
        var currentValue = parseInt(jQueryquantityInput.val());
        jQueryquantityInput.val(currentValue + 1);
        jQuery(updateCart).removeAttr('disabled');
    });
}

jQuery(document).ready(function() {
    addQuantityClickEvents();
});

jQuery(document.body).on('updated_wc_div', function() {
    addQuantityClickEvents();
});
